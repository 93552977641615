/* eslint-disable indent */
import { FC, useEffect, useState } from "react";
import { getClassDebrief } from "../services/api-service";
import { Wrapper } from "../ui-components";
import { GraphicsTransformation } from "../services/graphics-transformation";
import DimensionsDescription from "../components/DimensionsDescriptions";
import { IResultFetchFn } from "../models";
import ClassDebriefMainView from "../class-debrief-main-view";

export interface IGraphicsClassDebrief {
  teamId: string;
  teamName: string;
  appetite: number;
  value: number;
  mode: number;
  timeOver: boolean;
}

export const ClassDebrief: FC<{
  fetch: IResultFetchFn;
  teams: { id: string; name: string }[];
}> = (props) => {
  const [transformedClassDebrief, setTransformedClassDebrief] = useState<
    IGraphicsClassDebrief[]
  >([]);

  const inTimeSet = transformedClassDebrief.filter(
    (gcd: IGraphicsClassDebrief) => !gcd.timeOver
  );
  const outTimeSet = transformedClassDebrief.filter(
    (gcd: IGraphicsClassDebrief) => gcd.timeOver
  );

  useEffect(() => {
    getClassDebrief(props.fetch, props.teams)().then((classDebrief) => {
      setTransformedClassDebrief(
        GraphicsTransformation.transformClassDebrief(classDebrief)
      );
    });
  }, []);

  return (
    <>
      <div
        style={{
          padding: "24px 64px",
        }}
      >
        <h2
          className="text-6xl font-[Inter]"
          style={{
            fontWeight: 800,
            color: "#222244",
            marginBottom: "64px",
          }}
        >
          Quality of risk
          <span style={{ opacity: 0.2, margin: "auto 16px" }}>|</span>
          Class overview
        </h2>

        <DimensionsDescription />

        <Wrapper>
          {transformedClassDebrief ? (
            <ClassDebriefMainView
              inTimeSet={inTimeSet}
              outTimeSet={outTimeSet}
            />
          ) : (
            "Loading..."
          )}
        </Wrapper>
      </div>
    </>
  );
};
