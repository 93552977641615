import { useEffect, useState } from 'react';
import qs from 'query-string';
import Header from './components/Header';
import useDiagnostics from './hooks/useDiagnostics.hook';
import { ClassDebrief, IndividualDebrief } from './fireballs-debrief';
import buildAssetPath from './utils/buildAssetPath';
import { DebriefParamsDecode } from 'ledsp-sdk';
import { cnf } from './fireballs-debrief/config';
import { GamingSession } from './fireballs-debrief/models';

export const App = () => {
  const [gamingSession, setGamingSession] = useState<GamingSession>();
  const [gamingResults, setGamingResults] = useState<any>()
  useDiagnostics();

  const parsed = qs.parse(location.search);
  const ledspParams =
    parsed?.ledsp?.toString() && DebriefParamsDecode(parsed?.ledsp?.toString());
  const sessionId: string =
    ledspParams?.sessionId || parsed?.session?.toString();
  const playerId: string = ledspParams?.playerId || parsed?.player?.toString();

 const isAviable = (teamId: any)=>{return (gamingResults?.some((item) =>
  item.observations.some((observation) => 
      observation.object === teamId)))}

  useEffect(() => {
    if (sessionId)
      fetchSessionData(sessionId).then(res => setGamingSession(res));
      cnf.listResults()(sessionId).then(res=>setGamingResults(res))
  }, [sessionId]);

  if (!sessionId)
    return (
      <h1>
        Please specify ?session=[sessionId] or a ledsp compliant parameter
      </h1>
    );

  if (!gamingSession)
    return <h1>Gaming session with id {sessionId} not found!</h1>;

  return (
    <div className="w-screen h-screen flex flex-col bg-white">
      <Header brandImg={buildAssetPath('/images/logo_full.png')} />
      {playerId ? (
        <IndividualDebrief
          fetch={fetch(sessionId)}
          teams={Object.values(gamingSession.state.teams)}
          playerId={playerId}
        />
      ) : (
        <>
          <ClassDebrief
            fetch={fetch(sessionId)}
            teams={Object.values(gamingSession.state.teams)}
          />
          {
            <div className="flex flex-col items-center p-20 rounded-xl bg-slate-100 m-32">
              <p className="font-bold text-slate-700 text-4xl mb-10">
                Students Report Links
              </p>
              <ul className="flex flex-row w-full flex-wrap gap-10">
                {Object.values(gamingSession.state.teams).map(team => (
                  <li
                    key={team.id}
                    className= {isAviable(team.id)?"rounded-lg bg-white p-12 border border-b-2 border-gray-300 hover:border-b-4 hover:-translate-y-1 transition-all duration-200 ease-in-out":" rounded-lg bg-white p-12 opacity-75"}
                  >
                    <p className="uppercase text-xl mb-8 font-bold text-slate-500">
                      Team {team.name.toUpperCase()}
                    </p>
                    <ul>
                      {Object.values(gamingSession.state.candidatePlayers)
                        .filter(player => player.team === team.id)
                        .map(player => (
                          <li
                            key={player.id}
                            style={{
                              fontSize: '1.25rem',
                              lineHeight: '1.75rem',
                              textDecoration:
                                Object.values(
                                  gamingSession.state.playersParties
                                ).find(party => party.id === player.partyId)
                                  .spokesperson === player.id
                                  ? 'underline'
                                  : 'none',
                            }}
                          >

                          {isAviable(team.id)?
                            <a
                              href={`/?session=${sessionId}&player=${player.id}&team=${team.name}`}
                              target="_blank"
                            >
                           {player.traits.displayName}   
                            </a>: (
                                  <p> {player.traits.displayName}</p>
                                  )}
                          </li>
                        ))}
                    </ul>
                    {isAviable(team.id)?"": <p className='pt-2 text-lg font-bold text-gray-400'>(Waiting for the end of the game)</p>}
                  </li>       
                ))}
                 
              </ul>
            </div>
          }
        </>
      )}
    </div>
  );
};

const fetchSessionData = cnf.readSessionData();

const fetch = (sesId: string) => () => cnf.listResults()(sesId);
