import { IGraphicsClassDebrief } from '../class/class-debrief';
import { IGraphicsIndividualDebrief } from '../individual/individual-debrief';
import { IClassDebriefResult, IMember } from './api-service';
import { RiskQualityComponents } from './risk-quality-components';
import { TeamMemberConfiguration } from './team-member-configuration';

export class GraphicsTransformation {
  static transformClassDebrief = (classDebrief: IClassDebriefResult[]) => {
    const normalizedDebrief: IGraphicsClassDebrief[] = classDebrief.map(
      (cdr: IClassDebriefResult) => {
        const { riskAppetite, value } = RiskQualityComponents.of(
          cdr.riskQuality,
          cdr.differentialValue
        );
        return {
          teamId: cdr.teamId,
          teamName: cdr.teamName,
          appetite: riskAppetite,
          value: value,
          mode: cdr.mode,
          timeOver: cdr.timeOver,
        } as IGraphicsClassDebrief;
      }
    );
    return normalizedDebrief;
  };

  static transformIndividualDebrief = (individualDebrief: IMember[]) => {
    const normalizedDebrief: IGraphicsIndividualDebrief[] =
      individualDebrief.map((idr: IMember) => {
        const { gameRiskQuality, memberConfiguration } =
          TeamMemberConfiguration.from(idr.solutionA, idr.solutionB);

        // console.log(
        //   'individual debrief',
        //   idr.solutionA,
        //   idr.solutionB,
        //   memberConfiguration
        // );

        return {
          memberId: idr.memberId,
          gameRiskQuality,
          memberConfiguration,
          timeOver: idr.timeOver,
        } as IGraphicsIndividualDebrief;
      });

    return normalizedDebrief;
  };
}
