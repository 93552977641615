import React, { useEffect, useState } from 'react';
import {
  GameRiskQuality,
  finalRiskQualityComponents,
  solutions,
} from '../data/incidents';
import {
  getClassDebrief,
  getDebriefForTeam,
  IOriginalTeam,
} from '../services/api-service';
import { TeamMemberConfiguration } from '../services/team-member-configuration';
import { GlobalResult, Wrapper } from '../ui-components';
import { MatrixPlot } from '../class/matrix-plot';
import { GraphicsTransformation } from '../services/graphics-transformation';
import { IGraphicsClassDebrief } from '../class/class-debrief';
import DimensionsDescription from '../components/DimensionsDescriptions';
import { IResultFetchFn } from '../models';
import QualityOfDecisions from './quality-of-decisions';
import { Decision, QualityOfDecisionPlot } from './draft';

export interface IGraphicsIndividualDebrief {
  memberId: string;
  gameRiskQuality: GameRiskQuality;
  memberConfiguration: string;
  timeOver: boolean;
}

export interface IConfiguration {
  id: string;
  score: number[];
}

export const IndividualDebrief: React.FC<{
  fetch: IResultFetchFn;
  teams: IOriginalTeam[];
  playerId: string;
}> = props => {
  const [transformedIndividualDebrief, setTransformedIndividualDebrief] =
    useState<IGraphicsIndividualDebrief[]>([] as IGraphicsIndividualDebrief[]);
  const [transformedTeamDebrief, setTransformedTeamDebrief] = useState<any>();
  const [selectedMemberId, setSelectedMemberId] = useState('');
  const [playerDebrief, setSelectedMemberDebrief] =
    useState<IGraphicsIndividualDebrief>({} as IGraphicsIndividualDebrief);
  const [configurations, setConfigurations] = useState<IConfiguration[]>([]);
  const [teamConfigurations, setTeamConfigurations] = useState<
    IConfiguration[]
  >([]);
  const [transformedClassDebrief, setTransformedClassDebrief] = useState<
    IGraphicsClassDebrief[]
  >([]);

  const changeCurrentMemberViewTo = (memberId: string) => {
    setSelectedMemberId(memberId);
  };

  const getConfigurationsWithQuality = (
    gameRiskQuality: string
  ): IConfiguration[] =>
    solutions
      .filter(solution => solution.gameRiskQuality === gameRiskQuality)
      .filter(solution => solution.incidentId !== 'A1')
      .map(({ id }: { id: string }) => ({
        id,
        score: finalRiskQualityComponents.get(id),
      }));

  const getSelectedMemberDebriefAndConfigurations = () => {
    const selectedMemberDebrief = transformedIndividualDebrief.find(
      (deb: IGraphicsIndividualDebrief) => deb.memberId === selectedMemberId
    );

    if (selectedMemberDebrief) {
      setSelectedMemberDebrief(selectedMemberDebrief);

      setConfigurations([
        ...getConfigurationsWithQuality(selectedMemberDebrief.gameRiskQuality),
      ]);

      setTeamConfigurations([
        ...getConfigurationsWithQuality(transformedTeamDebrief.gameRiskQuality),
      ]);
    }
  };

  const transformTeamDebrief = (teamDebrief: any) => {
    const { gameRiskQuality, memberConfiguration } =
      TeamMemberConfiguration.from(
        teamDebrief.solutionA,
        teamDebrief.solutionB
      );

    setTransformedTeamDebrief({
      teamId: teamDebrief.teamId,
      gameRiskQuality,
      memberConfiguration,
      timeOver: teamDebrief.timeOver,
    });
  };

  useEffect(() => {
    getSelectedMemberDebriefAndConfigurations();
  }, [selectedMemberId]);

  useEffect(() => {
    getDebriefForTeam(
      props.fetch,
      props.teams
    )(props.playerId).then(individualDebrief => {
      setTransformedIndividualDebrief(
        GraphicsTransformation.transformIndividualDebrief(
          individualDebrief.members
        )
      );

      transformTeamDebrief(individualDebrief);

      changeCurrentMemberViewTo(props.playerId);
    });

    getClassDebrief(props.fetch, props.teams)().then(classDebrief => {
      setTransformedClassDebrief(
        GraphicsTransformation.transformClassDebrief(classDebrief)
      );
    });
  }, []);

  const inTimeSet = transformedClassDebrief.filter(
    (gcd: IGraphicsClassDebrief) => !gcd.timeOver
  );

  console.log('transformedTeamDebrief', transformedTeamDebrief);
  console.log('playerDebrief', playerDebrief);

  const IndividualDebriefMainView = () => (
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {transformedTeamDebrief && (
          <MatrixPlot
            showSelect={false}
            data={inTimeSet}
            highlightedTeamId={transformedTeamDebrief?.teamId}
          />
        )}
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '20px',
          width: '100%',
          marginTop: '100px',
        }}
      >
        {playerDebrief && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                fontWeight: 'bold',
              }}
            >
              {1 === 1 ? 'You are a ' : `Player ${selectedMemberId}`}
            </p>

            <GlobalResult riskQuality={playerDebrief.gameRiskQuality} />

            <p style={{ color: 'Gray' }}>Decisions quality drilldown</p>

            {transformedTeamDebrief &&
            playerDebrief &&
            transformedTeamDebrief.memberConfiguration &&
            playerDebrief.memberConfiguration ? (
              <QualityOfDecisionPlot
                teamFirstDecision={Decision.toFirstDecision(
                  Decision.convertToMeaningfulDecID(
                    transformedTeamDebrief.memberConfiguration
                  )
                )}
                decisions={[
                  Decision.toFirstDecision(
                    Decision.convertToMeaningfulDecID(
                      playerDebrief.memberConfiguration
                    )
                  ),
                  Decision.convertToMeaningfulDecID(
                    playerDebrief.memberConfiguration
                  ),
                ]}
              />
            ) : (
              'loading'
            )}

            {/* <QualityOfDecisions
              playerDebrief={playerDebrief}
              teamDebrief={transformedTeamDebrief}
              index={'Player'}
            /> */}
          </div>
        )}

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <p
            style={{
              fontWeight: 'bold',
            }}
          >
            Your team is a
          </p>

          <GlobalResult riskQuality={transformedTeamDebrief?.gameRiskQuality} />

          <p style={{ color: 'Gray' }}>Decisions quality drilldown</p>
          {transformedTeamDebrief &&
          transformedTeamDebrief.memberConfiguration ? (
            <QualityOfDecisionPlot
              teamFirstDecision={Decision.toFirstDecision(
                Decision.convertToMeaningfulDecID(
                  transformedTeamDebrief.memberConfiguration
                )
              )}
              decisions={[
                Decision.toFirstDecision(
                  Decision.convertToMeaningfulDecID(
                    transformedTeamDebrief.memberConfiguration
                  )
                ),
                Decision.convertToMeaningfulDecID(
                  transformedTeamDebrief.memberConfiguration
                ),
              ]}
            />
          ) : (
            'loading'
          )}

          {/* <QualityOfDecisions
            playerDebrief={transformedTeamDebrief}
            index={'Team'}
          /> */}
        </div>
      </div>
    </div>
  );

  const teamName: string = props.teams.find(
    t => t.id === transformedTeamDebrief?.teamId
  )?.name;
  const teamNameUpper: string =
    teamName?.length > 0
      ? teamName?.charAt(0).toUpperCase() + teamName?.slice(1)
      : '';

  return (
    <>
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
        `}
      </style>
      <div
        style={{
          padding: '24px 64px',
        }}
      >
        <h2
          style={{
            fontWeight: 800,
            fontSize: '3.5em',
            color: '#222244',
            fontFamily: 'Inter',
            marginBottom: '64px',
          }}
        >
          Quality of risk
          <span style={{ opacity: 0.2, margin: 'auto 16px' }}>|</span>
          Team {teamNameUpper} focus
        </h2>

        <DimensionsDescription />

        <Wrapper>
          {transformedIndividualDebrief ? (
            <IndividualDebriefMainView />
          ) : (
            'Loading...'
          )}
        </Wrapper>
      </div>
    </>
  );
};
