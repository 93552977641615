[{
  "observations": [{
    "type": "team",
    "object": "4ebbdd2d-9cd5-4d50-8a28-88d5cf1e4739",
    "solutionA": "4",
    "solutionB": "2",
    "mode": "off",
    "isTimeOver": false
  }, {
    "type": "player",
    "object": "64be6372-53e5-432a-8ca2-1202309790b4-9346dce070100ad0cbe5b9cb7d72328d",
    "solutionA": "2",
    "solutionB": "1",
    "mode": "off",
    "isTimeOver": false
  }]
}, {
  "observations": [{
    "type": "team",
    "object": "fe4b704a-ac20-42e3-b58e-704d6cfd7829",
    "solutionA": "2",
    "solutionB": "4",
    "mode": "off",
    "isTimeOver": false
  }, {
    "type": "player",
    "object": "64be6372-53e5-432a-8ca2-1202309790b4-54f52ea7c1342e9b2daf7b29ee12e85a",
    "solutionA": "1",
    "solutionB": "2",
    "mode": "off",
    "isTimeOver": false
  }]
}]