import { ListResultsFn, ReadSessionDataFn } from '../../models';
import { Config, Env } from '../api';

export class DevConfig implements Config {
  static matchEnv(env: Env) {
    return env && env.NODE_ENV && env.NODE_ENV === 'development';
  }

  static setup(env: Env) {
    return new DevConfig();
  }

  readSessionData(): ReadSessionDataFn {
    return async sessionId => {
      return require(`./local-tests/michelle-01/session.json`);
    };
  }

  listResults(): ListResultsFn {
    return async sessionId => {
      return require(`./local-tests/michelle-01/results.json`);
    };
  }
}
