import DimensionContainer from "./DimensionContainer";
import DimensionTitle from "./DimensionTitle";
import RiskTakingBadge from "./RiskTakingBadge";

const DimensionsDescription = () => (
  <>
    <h4 className="text-4xl text-slate-600 font-[Inter] font-bold mb-4">
      Dimensions
    </h4>
    <div
      className="grid"
      style={{
        gridTemplateColumns: "repeat(2, minmax(200px, 1fr))",
        gridTemplateRows: "repeat(2, minmax(200px, 1fr))",
        gridGap: "64px",
      }}
    >
      <DimensionContainer>
        <DimensionTitle text="Risk appetite" />
        <p>
          Risk appetite is the level of risk that an organization is prepared to
          accept in pursuit of its objectives, before action is deemed necessary
          to reduce the risk. It represents a balance between the potential
          benefits of innovation and the threats, that change inevitably brings.
          <br />
          <br />
          It is measured on a scale from 1 to 4, where 1 = risk adverse and 4 =
          risk hungry.
        </p>
      </DimensionContainer>
      <DimensionContainer>
        <DimensionTitle text="Created Value" />
        <p>
          Measure the intensity of the value created for the organization
          expressed on a scale from 1 to 4, where 1 = low value creation and 4 =
          high value creation.
        </p>
      </DimensionContainer>
      <DimensionContainer>
        <DimensionTitle text="Quality of risk zones" />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "32px",
            marginBottom: "4px",
          }}
        >
          <RiskTakingBadge
            fillColor="#CEF2CE"
            borderColor="rgb(186, 222, 200)"
          />
          <p style={{ fontWeight: "bold", color: "rgb(85 85 101)" }}>
            Good risk taker
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "4px",
          }}
        >
          <RiskTakingBadge
            fillColor="#F7F1B5"
            borderColor="rgb(227, 221, 140)"
          />
          <p style={{ fontWeight: "bold", color: "rgb(85 85 101)" }}>
            Random risk taker
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <RiskTakingBadge
            fillColor="#F0D8DC"
            borderColor="rgb(220, 190, 190)"
          />
          <p style={{ fontWeight: "bold", color: "rgb(85 85 101)" }}>
            Bad risk taker
          </p>
        </div>
      </DimensionContainer>
      <DimensionContainer>
        <DimensionTitle text="Time Factor" />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "32px",
          }}
        >
          <span
            style={{
              width: "38px",
              height: "38px",
              display: "inline-block",
              position: "relative",
              border: "2px solid Teal",
              borderRadius: "50%",
              marginRight: "16px",
            }}
          >
            <img
              src="https://cdn-icons-png.flaticon.com/128/850/850960.png"
              style={{
                height: "18px",
                width: "18px",
                position: "absolute",
                top: "-4px",
                right: "-8px",
              }}
              alt="X"
            />
          </span>
          <p
            style={{
              fontWeight: "bold",
              color: "rgb(85 85 101)",
            }}
          >
            Team under Time Pressure
          </p>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <span
            style={{
              width: "38px",
              height: "38px",
              display: "inline-block",
              position: "relative",
              border: "2px solid Teal",
              borderRadius: "50%",
              marginRight: "16px",
            }}
          />
          <p
            style={{
              fontWeight: "bold",
              color: "rgb(85 85 101)",
            }}
          >
            Team without Time Pressure
          </p>
        </div>
      </DimensionContainer>
    </div>
  </>
);

export default DimensionsDescription;
