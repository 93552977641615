import clsx from 'clsx';
import { useEffect, useState } from 'react';
import buildAssetPath from '../../utils/buildAssetPath';

enum QualityOfRisk {
  VeryBad = 'VeryBad',
  Bad = 'Bad',
  Good = 'Good',
  VeryGood = 'VeryGood',
}

class QualityOfRiskScale {
  static toValues() {
    return [
      QualityOfRisk.VeryBad,
      QualityOfRisk.Bad,
      QualityOfRisk.Good,
      QualityOfRisk.VeryGood,
    ];
  }

  static isGood(dec: QualityOfRisk) {
    return [QualityOfRisk.Good, QualityOfRisk.VeryGood].includes(dec);
  }

  static isBad(dec: QualityOfRisk) {
    return [QualityOfRisk.Bad, QualityOfRisk.VeryBad].includes(dec);
  }
}

const DECISION_EVALUATION: [string, QualityOfRisk][] = [
  ['A1', QualityOfRisk.VeryGood],
  ['A2', QualityOfRisk.Good],
  ['A3', QualityOfRisk.Bad],
  ['A4', QualityOfRisk.VeryBad],
  ['A1_B1', QualityOfRisk.VeryBad],
  ['A1_B2', QualityOfRisk.Bad],
  ['A1_B3', QualityOfRisk.Good],
  ['A1_B4', QualityOfRisk.VeryGood],
  ['A2_B1', QualityOfRisk.VeryBad],
  ['A2_B2', QualityOfRisk.Bad],
  ['A2_B3', QualityOfRisk.Good],
  ['A2_B4', QualityOfRisk.VeryGood],
  ['A3_B1', QualityOfRisk.VeryBad],
  ['A3_B2', QualityOfRisk.Bad],
  ['A3_B3', QualityOfRisk.VeryGood],
  ['A3_B4', QualityOfRisk.Good],
  ['A4_B1', QualityOfRisk.Good],
  ['A4_B2', QualityOfRisk.Bad],
  ['A4_B3', QualityOfRisk.VeryBad],
  ['A4_B4', QualityOfRisk.VeryGood],
];

export class Decision {
  private static BASE_URL = '/en/assets/messages/narrative/';

  static isFirstDecision(decId: string) {
    return decId.length === 2;
  }

  static isSecondDecision(decId: string) {
    return !this.isFirstDecision(decId);
  }

  static toFirstDecision(decId: string) {
    if (this.isFirstDecision(decId)) return decId;
    return decId.split('_')[0];
  }

  static toSecondDecision(decId: string) {
    if (this.isFirstDecision(decId)) return '';
    return decId.split('_')[1];
  }

  static toIssueId(decId: string) {
    if (this.isFirstDecision(decId)) return 'A1';
    return decId.split('_')[1];
  }

  static issueToUrl(id: string) {
    switch (id) {
      case 'A1':
        return `${this.BASE_URL}sceltaA_claim.txt`;
      case 'B1':
        return `${this.BASE_URL}effects-A1.txt`;
      case 'B2':
        return `${this.BASE_URL}effects-A2.txt`;
      case 'B3':
        return `${this.BASE_URL}effects-A3.txt`;
      case 'B4':
        return `${this.BASE_URL}effects-A4.txt`;
    }
  }

  static decisionToUrl(decId: string) {
    if (this.isFirstDecision(decId))
      return `${this.BASE_URL}decisions_A1_${decId[1]}.txt`;
    else {
      const [f, s] = decId.split('_');
      return `${this.BASE_URL}decisions_B${f[1]}_${s[1]}.txt`;
    }
  }

  // questa rimane qui, chi la toglie lo licenzio
  // una fuckYou decision è scritta nella forma B{prima decisione}.{seconda decisione}
  static convertToMeaningfulDecID(fuckYou: string) {
    const [f, s] = fuckYou.split('.');
    return `A${f[1]}_B${s}`;
  }

  static toChosen(decId: string, teamFirstDecision: string) {
    if (Decision.isFirstDecision(decId)) return decId;
    return [teamFirstDecision, Decision.toSecondDecision(decId)].join('_');
  }
}

const filterDecisionEvaluations = (
  teamFirstDecision: string,
  decisionNumber: number
) => {
  if (decisionNumber == 0)
    return ([id, ev]: [string, QualityOfRisk]) => Decision.isFirstDecision(id);

  if (decisionNumber == 1)
    return ([id, ev]: [string, QualityOfRisk]) =>
      Decision.isSecondDecision(id) && id.startsWith(teamFirstDecision);
};

interface Data {
  teamFirstDecision: string;
  decisions: [string, string];
}

export const QualityOfDecisionPlot: React.FC<Data> = ps => {
  const [selected, setSelected] = useState('');

  const axis = <Axis scale={QualityOfRiskScale.toValues()} />;
  const series = ps.decisions.map((dec, i) => (
    <Serie
      scale={QualityOfRiskScale.toValues()}
      name={`D.${i + 1}`}
      data={DECISION_EVALUATION.filter(
        filterDecisionEvaluations(ps.teamFirstDecision, i)
      )}
      chosen={Decision.toChosen(dec, ps.teamFirstDecision)}
      selected={selected}
      onSelect={setSelected}
    />
  ));

  const texts = selected ? <Texts id={selected} /> : null;

  return (
    <div>
      <pre style={{ display: 'none' }}>
        {ps.teamFirstDecision} {ps.decisions.join(',')}
      </pre>
      <Grid axis={axis} series={series} text={texts} />;
    </div>
  );
};

const Texts: React.FC<{ id: string }> = ps => (
  <>
    <TextOfIssue id={Decision.toIssueId(ps.id)} />
    <TextOfDecision id={ps.id} />
  </>
);

const Grid: React.FC<{
  axis: React.ReactElement;
  series: React.ReactElement[];
  text: React.ReactElement;
}> = ps => (
  <div className="py-4 flex flex-col items-center">
    <div className="flex py-5 flex-row justify-center">{ps.axis}</div>
    <div className="flex flex-col h-full gap-16 items-center">
      {ps.series.map((serie, i) => (
        <div key={i} className="w-[450px] relative">
          {serie}
        </div>
      ))}
      <div className="py-4 w-[500px] h-[380px]">{ps.text}</div>
    </div>
  </div>
);

const Axis: React.FC<{ scale: string[] }> = props => (
  <>
    {props.scale.map((value, i) => (
      <div key={i} className="w-[100px] flex justify-center">
        <p className="text-xs font-semibold">{value}</p>
      </div>
    ))}
  </>
);

const Serie: React.FC<{
  scale: QualityOfRisk[];
  name: string;
  data: [string, QualityOfRisk][];
  chosen: string;
  selected: string;
  onSelect: (id: string) => void;
}> = ps => (
  <>
    <div className="absolute top-3 -left-9">{ps.name}</div>
    <Line />

    <div style={{ display: 'none' }}>
      {ps.name} {ps.chosen}
    </div>

    <div className="flex flex-row z-40 justify-evenly absolute w-full">
      {ps.scale
        .map(v => ({
          value: v,
          id: ps.data.find(datum => datum[1] === v)[0],
        }))
        .map((dat, i) => (
          <Circle
            key={i}
            id={dat.id}
            value={dat.value}
            onClick={id => {
              if (id === ps.selected) ps.onSelect('');
              else ps.onSelect(id);
            }}
            chosen={dat.id === ps.chosen}
            highlighted={dat.id === ps.selected}
          />
        ))}
    </div>
  </>
);

const Line = () => (
  <div className="absolute top-4 flex flex-row items-center w-full">
    <div className="w-full border-t-2 border-gray-400"></div>
    <div className="border-t-8 border-t-transparent border-b-8 border-b-transparent border-l-transparent-8 border-l-transparent-400 h-0 w-0"></div>
  </div>
);

const Circle: React.FC<{
  id: string;
  value: QualityOfRisk;
  chosen: boolean;
  highlighted: boolean;
  onClick: (id: string) => void;
}> = ps => {
  const isGood = QualityOfRiskScale.isGood(ps.value);
  const isBad = QualityOfRiskScale.isBad(ps.value);
  const isChosen = ps.chosen;
  const isHighlighted = ps.highlighted;

  return (
    <div
      onClick={() => ps.onClick(ps.id)}
      className={clsx(
        'h-[50px] w-[50px] inline-block rounded-full cursor-pointer',
        {
          'border-2 border-dashed': !isChosen,
          'bg-red-500': isChosen && isBad,
          'bg-green-500': isChosen && isGood,
          'outline outline-yellow-300 outline-6': isHighlighted,
          'border-red-500': isBad,
          'border-green-500': isGood,
        }
      )}
    >
      <p style={{ display: 'none' }}>{ps.id}</p>
    </div>
  );
};

const TextOfIssue: React.FC<{ id: string }> = ps => (
  <div className="p-2">
    <p className="font-bold">Issue</p>
    <TextFromUrl url={Decision.issueToUrl(ps.id)} />
  </div>
);

const TextOfDecision: React.FC<{ id: string }> = ps => (
  <div className="p-2">
    <p className="font-bold">Decision</p>
    <TextFromUrl url={Decision.decisionToUrl(ps.id)} />
  </div>
);

const TextFromUrl: React.FC<{ url: string }> = ps => {
  const [text, setText] = useState('loading...');
  useEffect(() => {
    fetch(buildAssetPath(ps.url)).then(response =>
      response.text().then(setText)
    );
  }, [ps.url]);

  return <p>{text}</p>;
};
