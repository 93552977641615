import { IGraphicsClassDebrief } from "./class/class-debrief";
import { MatrixPlot } from "./class/matrix-plot";

const ClassDebriefMainView = ({
  inTimeSet,
  outTimeSet,
}: {
  inTimeSet: IGraphicsClassDebrief[];
  outTimeSet: IGraphicsClassDebrief[];
}) => (
  <>
    <MatrixPlot data={inTimeSet} showSelect />
    <div className="flex flex-col items-center p-20 mx-16 rounded-xl bg-slate-100">
      <p className="mb-10 text-4xl font-bold text-slate-700">
        Decision Paralysis Zone
      </p>
      <br />
      {outTimeSet.length ? (
        outTimeSet.map(({ teamId }: IGraphicsClassDebrief) => (
          <span
            key={teamId}
            className="font-bold text-center text-white rounded-3xl"
            style={{
              backgroundColor: "Indigo",
              padding: "4px 12px",
            }}
          >
            Team {teamId}
          </span>
        ))
      ) : (
        <p className="m-auto text-4xl text-center text-slate-400">
          No data to show.
        </p>
      )}
    </div>
  </>
);

export default ClassDebriefMainView;
