import { Configs } from './configs';
import { DevConfig } from './confs/dev-config';
import { ProdConfig } from './confs/prod-config';

Configs.register(DevConfig);
Configs.register(ProdConfig);

export const cnf = Configs.fromEnv({
  NODE_ENV: process.env.NODE_ENV,
  API_ENDPOINT: process.env.API_ENDPOINT,
});
