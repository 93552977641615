import HttpClient from "../../../services/HttpClient.service";
import { ListResultsFn, ReadSessionDataFn } from "../../models";
import { Config, Env } from "../api";

export class ProdConfig implements Config {
  static matchEnv(env: Env) {
    return env && env.NODE_ENV && env.NODE_ENV !== "development";
  }

  static setup(env: Env) {
    return new ProdConfig(env);
  }

  constructor(public env: Env) {}

  readSessionData(): ReadSessionDataFn {
    return async (sessionId: string) =>
      HttpClient.get(`${this.env.API_ENDPOINT}/gaming-sessions/${sessionId}`);
  }

  listResults(): ListResultsFn {
    return async (sesId: string) =>
      HttpClient.get(
        `${this.env.API_ENDPOINT}/game-results/payloads?gamingSessionIds[]=${sesId}`
      );
  }
}
