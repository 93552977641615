import p5Types from "p5";

export default class Circle {
  baseSize = 42;
  size = 42;
  hovered = false;
  hoveredSize = 48;

  // eslint-disable-next-line max-params
  constructor(
    public readonly x: number,
    public readonly y: number,
    public label: string,
    public readonly hasBadge: boolean,
    public isHighlighted: boolean
  ) {}

  changeLabel(label: string) {
    this.label = label;
    return this;
  }

  changeIsHighlighted(isH: boolean) {
    this.isHighlighted = isH;
    return this;
  }

  update(p5: p5Types) {
    this.hovered =
      p5.mouseX > this.x - this.size * 0.5 &&
      p5.mouseX < this.x + this.size * 0.5 &&
      p5.mouseY > this.y - this.size * 0.5 &&
      p5.mouseY < this.y + this.size * 0.5;

    if (this.hovered && this.size !== this.hoveredSize)
      this.size = p5.lerp(this.size, this.hoveredSize, 0.1);
    else if (!this.hovered && this.size !== this.baseSize)
      this.size = p5.lerp(this.size, this.baseSize, 0.4);
  }

  collidesWith(x: number, y: number): boolean {
    return (
      x > this.x - this.size / 2 &&
      x < this.x + this.size / 2 &&
      y > this.y - this.size / 2 &&
      y < this.y + this.size / 2
    );
  }

  draw(p5: p5Types, badge: any) {
    this.update(p5);

    p5.strokeWeight(2);

    p5.stroke(this.collidesWith(p5.mouseX, p5.mouseY) ? "#00A0F0" : "Teal");
    p5.fill("White");
    p5.ellipse(this.x, this.y, this.size, this.size);

    p5.stroke("red");
    p5.noFill();

    if (this.hasBadge) p5.image(badge, this.x + 8, this.y - 24, 20, 20);

    if (this.isHighlighted) {
      p5.stroke("SlateBlue");
      p5.strokeWeight(1);       //removed animation
      p5.ellipse(
        this.x,
        this.y,
        this.baseSize + 18,   //removed animation
        this.baseSize + 18    //removed animation
      );
      
  
      p5.noStroke();
      p5.fill("Indigo");
      p5.textSize(16);
      p5.textStyle(p5.BOLD);
      p5.textAlign(p5.CENTER, p5.CENTER);
      p5.text("Your team", this.x, this.y + 45);
      
      p5.textSize(12);
      p5.fill("DarkSlateBlue")
      p5.textStyle(p5.BOLD);
      p5.textAlign(p5.CENTER, p5.CENTER);
      p5.text(this.label, this.x, this.y + (this.label.length>18?85:70));
    } else {
      p5.noStroke();
      p5.fill("DarkSlateBlue");
      p5.textAlign("center");
      p5.textSize(12);
      p5.textStyle(p5.BOLD);
      p5.textAlign(p5.CENTER, p5.CENTER);
      p5.text(this.label, this.x, this.y + (this.label.length>22? 60:48));
    }
  }
}
