const RiskTakingBadge = ({ fillColor, borderColor }) => (
  <span
    style={{
      width: "48px",
      height: "48px",
      backgroundColor: fillColor,
      borderBottom: `2px solid ${borderColor}`,
      display: "inline-block",
      position: "relative",
      marginRight: "16px",
      borderRadius: "4px",
    }}
  />
);

export default RiskTakingBadge;
