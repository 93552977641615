import { Config, ConfigClass, Env } from './api';

export abstract class Configs {
  private static cfs: ConfigClass<any>[] = [];
  private static def: ConfigClass<any> = null;

  static register(cls: ConfigClass<any>, isDefault: boolean = false) {
    this.cfs.push(cls);
    if (isDefault) this.def = cls;
  }

  static fromEnv(env: Env): Config {
    const cnf = this.cfs.find(cnf => cnf.matchEnv(env)) || this.def;
    if (!cnf) throw new Error(`No configuration found`);
    return cnf.setup(env);
  }
}
