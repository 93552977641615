/* eslint-disable indent */
import { solutions } from '../data/incidents';
import { FireballsGameResultPayload, IResultFetchFn } from '../models';

export interface IClassDebriefResult {
  teamId: string;
  teamName: string;
  riskQuality: number;
  differentialValue: number;
  mode: number;
  timeOver: boolean;
}

export const getClassDebrief =
  (fetch: IResultFetchFn, teams: IOriginalTeam[]) => async () => {
    const res = await fetch();
    return extractClassDebrief(res, teams);
  };

export interface IOriginalTeam {
  id: string;
  name: string;
}

export function extractClassDebrief(
  payloads: FireballsGameResultPayload[],
  teams: IOriginalTeam[]
): IClassDebriefResult[] {
  return payloads
    .map(p => observationsToClassDebriefResult(p, teams))
    .filter(p => p !== null);
}

function observationsToClassDebriefResult(
  payload: FireballsGameResultPayload,
  teams: IOriginalTeam[]
): IClassDebriefResult | null {
  const team = payload.observations.find(o => o.type === 'team');
  if (!team) return null;

  const solutionA = solutions.find(({ id }) => id === `A1.${team.solutionA}`);
  const solutionB = solutions.find(
    ({ id }) => id === `B${team.solutionA}.${team.solutionB}`
  );

  const originalTeam = teams.find(t => t.id === team.object);

  return {
    teamId: team.object,
    teamName: originalTeam.name,
    riskQuality: solutionB ? solutionB.riskQuality : solutionA.riskQuality,
    differentialValue: solutionB
      ? solutionB.differentialValue
      : solutionA.differentialValue,
    mode: team.mode === 'on' ? 1 : 0,
    timeOver: team.isTimeOver,
  };
}

const observationsToIndividualDebriefResult = (
  payload: FireballsGameResultPayload,
  teams: IOriginalTeam[]
): IIndividualDebriefResult => {
  const team = payload.observations.find(o => o.type === 'team');

  const solutionA = solutions.find(({ id }) => id === `A1.${team.solutionA}`);
  const solutionB = solutions.find(
    ({ id }) => id === `B${team.solutionA}.${team.solutionB}`
  );

  const originalTeam = teams.find(t => t.id === team.object);

  return {
    teamId: team.object,
    teamName: originalTeam.name,
    solutionA: solutionA.id,
    solutionB: solutionB ? solutionB.id : null,
    timeOver: team.isTimeOver,
    members: payload.observations
      .filter(o => o.type === 'player')
      .map(p => {
        const solutionA = solutions.find(
          ({ id }) => id === `A1.${p.solutionA}`
        );
        const solutionB = solutions.find(
          ({ id }) => id === `B${p.solutionA}.${p.solutionB}`
        );

        return {
          memberId: p.object,
          solutionA: solutionA.id,
          solutionB: solutionB ? solutionB.id : null,
          timeOver: p.isTimeOver,
        };
      }),
  };
};

export interface IIndividualDebriefResult {
  teamId: string;
  teamName: string;
  solutionA: string;
  solutionB: string;
  timeOver: boolean;
  members: IMember[];
}

export interface IMember {
  memberId: string;
  solutionA: string;
  solutionB?: string;
  timeOver: boolean;
}

export const getDebriefForTeam =
  (fetch: IResultFetchFn, teams: IOriginalTeam[]) =>
  async (playerId: string) => {
    const res = await fetch();

    const teamId: string = res
      .filter(Boolean)
      .find(payloadEntry =>
        payloadEntry.observations.some(
          observation =>
            observation.type === 'player' && observation.object === playerId
        )
      )
      ?.observations.find(observation => observation.type === 'team').object;

    const payload = res
      .filter(Boolean)
      .find(payloadEntry =>
        payloadEntry.observations.some(
          observation =>
            observation.type === 'team' && observation.object === teamId
        )
      );

    return payload
      ? observationsToIndividualDebriefResult({ teamId, ...payload }, teams)
      : {
          teamId: '',
          solutionA: '',
          solutionB: '',
          timeOver: false,
          members: [],
        };
  };

export enum GameRiskQuality {
  BAD = 'bad',
  AVERAGE = 'average',
  GOOD = 'good',
}
