/* eslint-disable indent */
import { GameRiskQuality } from "./data/incidents";

export const Wrapper = ({ children }) => (
  <div className="flex flex-col">{children}</div>
);

const getLabelFor = (riskQuality: GameRiskQuality) => {
  switch (riskQuality) {
    case GameRiskQuality.GOOD:
      return "Good risk taker";
    case GameRiskQuality.AVERAGE:
      return "Random risk taker";
    case GameRiskQuality.BAD:
      return "Bad risk taker";
    default:
      return "";
  }
};

const getColorFor = (riskQuality: GameRiskQuality) => {
  switch (riskQuality) {
    case GameRiskQuality.GOOD:
      return "green";
    case GameRiskQuality.AVERAGE:
      return "#ffc107";
    case GameRiskQuality.BAD:
      return "red";
    default:
      return "";
  }
};

interface IProps {
  riskQuality: GameRiskQuality;
}

export const GlobalResult = ({ riskQuality }: IProps) => (
  <p
    style={{
      color: getColorFor(riskQuality),
      fontSize: "2.2em",
      fontWeight: "bold",
      fontFamily: "Inter",
      textShadow: "0 1px 1px #a7a7a7",
    }}
  >
    {getLabelFor(riskQuality)}
  </p>
);
