const DimensionContainer = ({ children }) => (
  <div
    className="w-full h-full"
    style={{
      borderLeft: "4px solid Gainsboro",
      paddingLeft: "20px",
    }}
  >
    {children}
  </div>
);

export default DimensionContainer;
